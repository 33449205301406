import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "5kco website design",
  "featuredImage": "images/5kco1.png",
  "coverAlt": "5kco website design",
  "description": "Web Developent, design and UX for Vancouver based apparel company, 5kco",
  "date": "2018-03-14",
  "dateModified": "2018-03-14",
  "category": "portfolio",
  "pinned": false,
  "featured": false,
  "tags": ["Front End Development", "stuff", "other"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`My friend at 5kco needed his website launched - wanted something `}<strong parentName="p">{`minimalist`}</strong>{` and bold to accurately represent his clothing company - the result can be seen below.`}</p>
    <p>{`Fully custom mobile and multi-device ready. Check it out at `}<a parentName="p" {...{
        "href": "http://www.5kco.com/"
      }}>{`www.5kco.com`}</a></p>
    <p>{`Other features: Back end website to update t-shirts sold physically for campaign, sidebar menu reveals partially on hover to show `}<strong parentName="p">{`icons`}</strong>{`, on click shows text and icons.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skills Used----------`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HTML, CSS, JavaScript`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`UX`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`JQuery`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PHP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "screenshots",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#screenshots",
        "aria-label": "screenshots permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Screenshots`}</h2>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1440px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/008124d796d624b494f7027287c6243d/444d8/5kco1.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "57.22222222222223%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACoklEQVQoz12T+y+bURjH+zcuyxaXn0iodozyemulVGy6UAy9GSJzmeuYYhdqwpTqVVu2EosRmcQtYcXatz7L+8Yu8SSfnHOenHyf75PzHNWpp5D1cIBUSgJukKQU6bSkkEqlkCRJQT7Lq5y7SadJp9NcXFxgNBoxmWoRBIHMzExUgHLRZrVht1vpGxxges7NoGuSjs5OKquqaLY00tHlxGx+RrvdhqWpieoaEwZjNa12B729PUpBOVSyq6urKwRBT4mulHpLE7bOLlpsNoymWjKysikt1VFVW0OeWk2BpgCtVkupqEdbXEKutpBiQeTo+PiPIEobkWCY5aUlpqZc+FZXWXDPMjEyxGhfD2/Hhugfn8TW/YoWcx0VFQZmXFPMTM8wNjnNx7l5Li+v/gnKLQc3vuANR/CtR/BHogSiMWWvEInS3jOIRjRS1+SkuqWbOW8YfyyONxrHt7HNdTL5n0NJYtEfYdbjV/jkDbLgCzG34uPDZy+dvQNYHR00ttpx9AzTPexiNbaNb2ML/+YO4a1dfiVTt4I3NySTSZZD6yysBVj0BZiYec/r0XHmlzwMu6bRP6miXF9Bbr6GnDwNOkMN84EwnthXPLE4/vg3rv8K3sbW9jaRcAjPkofmtpe0WbswW6xY2p2Ynpox1Vt4rK8lN09DfrHAwlqIgx+H7HzfZ+/g8HbsQHVyckIoFMLZ0cXzhkbKRANarQ6dvoasjGzKBJGGhkYqTWYeZOVw7/5D8h6VMOZ6x8qqj1B4g2gsrryDIuh2u9FoNBSX6CgrE8hX51NUVIheFFGr1TgdDoYG+nnRZkMsFxXM9fW8GRkkGAiys7PLemSTvf19EomfqBKJBEdHR5ydnSmcnp4qyM7Pz89JXF4qv+JuSHdy8izLWr8BIGG+UGbp8TIAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "5kco hero section",
              "title": "5kco hero section",
              "src": "/static/008124d796d624b494f7027287c6243d/89066/5kco1.png",
              "srcSet": ["/static/008124d796d624b494f7027287c6243d/53918/5kco1.png 360w", "/static/008124d796d624b494f7027287c6243d/242a6/5kco1.png 720w", "/static/008124d796d624b494f7027287c6243d/89066/5kco1.png 1440w", "/static/008124d796d624b494f7027287c6243d/36aa2/5kco1.png 2160w", "/static/008124d796d624b494f7027287c6243d/f9c26/5kco1.png 2880w", "/static/008124d796d624b494f7027287c6243d/444d8/5kco1.png 4134w"],
              "sizes": "(max-width: 1440px) 100vw, 1440px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`5kco hero section`}</figcaption>{`
  `}</figure></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      